import logo from "./logo.webp"

const  clientData = {
   client_entity: 23,
   attorney_firm: "apexipgroup.com",
    attorney_name: 'Jeffrey Herman',
    attorney_number: '877-439-9717',
    attorney_email: 'info@apexipgroup.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_apex_intellectual_property_group+(720p).mp4',
    mainColor: '#1F263A',
    secondaryColor: '#27418B',
    siteLink: 'http://apexipgroup.com',
    logo
}

export default clientData